import store from '@/store';
import { Toast } from 'cube-ui';
import { getQueryStringArgs } from './until';
import { login} from '../api/sign-in';
import { getAccessToken } from './common';

const showToast = (txt, type = 'txt', time = 1500) =>
  Toast.$create({ txt, type, time }, true).show();
const setToken = token => store.commit('SET_TOKEN', token);
const setPostList = list => store.commit('SET_ORG_AND_POST_LIST', list);
// let orgId = '';
// let postId = '';
const handleOALoginError = (error, next) => {
  showToast(error);
  next({ name: 'error-oa' });
};

const handleLogin = next => {
  localStorage.setItem(
    'USERNAME',
    btoa(
      JSON.stringify({
        loginSource: 1
      })
    )
  );
  next({ name: 'home' });
  // if (list.length > 1) {
  //   next({ name: 'select-identity' });
  // } else if (list.length === 1) {
  //   const [item] = list;

  // loginFinish(orgId, postId, 1).then((res) => {
  //   if (res.flag) {
  //     setToken(res.data.token);
  //     setUserInfo(res.data);
  //   } else {
  //     if (res.errorCode === config.ERR_SIGN_FIRST_RESET || res.errorCode === config.ERR_SIGN_TIME_OUT) {
  //       let data = {
  //         account: res.data.userAccount,
  //         pwd: res.data.passWord,
  //         tenancyId: res.data.tenancyId,
  //         deptId: res.data.deptId,
  //         postId: res.data.postId,
  //         loginSource: 1
  //       };
  //       localStorage.setItem('USERNAME', btoa(JSON.stringify(data)));
  //       if (res.errorCode === config.ERR_SIGN_FIRST_RESET) {
  //         // 首次登录未修改密码
  //         next({ name: 'first-reset' });
  //       } else {
  //         // 距离上次登陆超过72小时
  //         next({ name: 'authentication' });
  //       }
  //     } else {
  //       clearToken();
  //       handleOALoginError(res.errorMsg, next);
  //     }
  //   }
  // });
  // }
};

export async function handleOALogin(next) {
  const { account, password } = getQueryStringArgs();

  await getAccessToken();

  await login(account, password, null, 1)
    .then(res => {
      if (res.flag) {
        setToken(res.data.token);
        setPostList(res.data.orgAndPostList);
        // orgId = res.data.orgId;
        // postId = res.data.postId;
        handleLogin(next);
      } else {
        handleOALoginError(res.errorMsg, next);
      }
    })
    .catch(() => {
      handleOALoginError('网络异常', next);
    });
}
