import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';

import actions from './actions';
import getters from './getters';
import business from './modules/business';
import clue from './modules/clue';
import contacts from './modules/contacts';
import cust from './modules/cust';
import follow from './modules/follow';
import sign from './modules/sign';
import tree from './modules/tree';
import workreport from './modules/workreport';
import mutations from './mutations';
import state from './state';

Vue.use(Vuex);

const pathWithoutLSAndSS = [];
// 存localStorage的数据
const pathWithoutSS = ['token', 'userInfo', 'orgAndPostList'];

const vuexWithSS = persistedState({
  key: 'SS',
  storage: window.sessionStorage,
  reducer: (vuexState) => {
    const sessionState = { ...vuexState };
    const path = [...pathWithoutSS, ...pathWithoutLSAndSS];

    path.forEach((item) => {
      // eslint-disable-next-line no-prototype-builtins
      if (sessionState.hasOwnProperty(item)) {
        delete sessionState[item];
      }
    });
    return vuexState;
  }
});

const vuexWithLS = persistedState({
  key: 'LS',
  storage: window.localStorage,
  reducer: (vuexState) => {
    const storage = {};

    pathWithoutSS.forEach((item) => {
      // eslint-disable-next-line no-prototype-builtins
      if (vuexState.hasOwnProperty(item)) {
        storage[item] = vuexState[item];
      }
    });

    return storage;
  }
});

// eslint-disable-next-line
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    clue,
    cust,
    business,
    sign,
    follow,
    contacts,
    workreport,
    tree
  },
  plugins: [vuexWithSS, vuexWithLS]
});
