import loadingComponent from './loading.vue';

let $vm = null;
let Loading = {};
Loading.install = (Vue, options = {}) => {
  if (!$vm) {
    const LoadingPlugin = Vue.extend(loadingComponent);
    $vm = new LoadingPlugin(options).$mount();
    document.body.appendChild($vm.$el);
  }
  $vm.show = false;
  let count = 0;
  let loading = {
    show() {
      count++;
      $vm.show = true;
    },
    hide() {
      count--;
      if (count <= 0) {
        $vm.show = false;
      }
    }
  };
  if (!Vue.$loadingG) Vue.$loadingG = loading;
  Vue.prototype.$loadingG = Vue.$loadingG;
  // Vue.mixin({
  //   created() {
  //     this.$loading = Vue.$loading;
  //   }
  // });
};

export default Loading;
