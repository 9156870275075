<template>
  <div id="app">
    <transition :name="transitionName">
      <keep-alive :include="keepAlive">
        <router-view class="router-view" />
      </keep-alive>
    </transition>
  </div>
</template>
<script type='text/javascript'>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      transitionName: 'vux-pop-out'
    };
  },
  computed: {
    ...mapGetters(['keepAlive'])
  },
  watch: {
    $route(to, from) {
      if (to.meta.index > from.meta.index) {
        this.transitionName = 'vux-pop-in';
      } else {
        this.transitionName = 'vux-pop-out';
      }
    }
  },
  mounted() {
    // document.body.addEventListener(
    //   "touchmove",
    //   function(e) {
    //     if (!e.isSCROLL) {
    //       e.preventDefault(); // 阻止默认事件(上下滑动)
    //     }
    //   },
    //   { passive: false }
    // ); // passive防止阻止默认事件不生效
  }
};
</script>

<style lang="scss">
.router-view{
  position: absolute;
  width: 100%;
  height:100%;
  -webkit-transition: all .3s cubic-bezier(.55,0,.1,1);
  -moz-transition: all .3s cubic-bezier(.55,0,.1,1);
  -ms-transition: all .3s cubic-bezier(.55,0,.1,1);
  -o-transition: all .3s cubic-bezier(.55,0,.1,1);
  transition: all .3s cubic-bezier(.55,0,.1,1);
 }
.vux-pop-out-enter-active,
.vux-pop-out-leave-active,
.vux-pop-in-enter-active,
.vux-pop-in-leave-active {
 position: absolute;
 height: 100%;
 backface-visibility: hidden;
 perspective: 1000;
 transition: all 0.3s;
}
.vux-pop-out-enter {
 transform: translate3d(-100%, 0, 0);
 opacity: 0;
}
.vux-pop-out-leave-active {
 transform: translate3d(100%, 0, 0);
 opacity: 0;
}
.vux-pop-in-enter {
 transform: translate3d(100%, 0, 0);
 opacity: 0;
}
.vux-pop-in-leave-active {
 transform: translate3d(-100%, 0, 0);
 opacity: 0;
}
#app {
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    color: #2c3e50;
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
