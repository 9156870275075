import { ssoRequest } from './index';

/**
 * 获取临时token
 */
export const getToken = () => ssoRequest.post('/web/tempToken');

/**
 * sign_in
 * @param mobile string
 * @param passWord string
 * @param vcode string
 */
export const login = (account, passWord, picCode = '', loginSource, code) => ssoRequest.post('/web/loginCheck', {
  account,
  passWord,
  picCode,
  loginSource,
  code
});

/**
 * 移动端登录完成接口
 */
export const loginFinish = (orgId, postId, loginSource) => ssoRequest.post('/web/loginFinish', { orgId, postId, loginSource });

/**
 * get cert Pic
 * @returns base64
 */
export const getCertPic = () => ssoRequest.post('/web/makeCertPic');

/**
 * 切换岗位
 * params orgId int
 * params postId int
 * @returns UserInfo
 */
export const changeOrg = (orgId, postId, loginSource) => ssoRequest.post('/web/changeOrg', { orgId, postId, loginSource });

/**
 * 切换角色
 * params roleId int
 * @returns UserInfo
 */
// export const changeRole = (roleId) => ssoRequest.post('/web/changeRole', { roleId });
export const changeOrgRole = data => ssoRequest.post('/web/changeOrgRole', data);

/**
 * 获取菜单
 * params data
 * @returns Menus Info
 */
export const getMenus = (data) => ssoRequest.post('/web/loadRoleMenus', data);

/**
 * 第一次登录用户通过原密码修改密码
 * @param oldPwd string
 * @param newPwd string
 */
export const resetPwdFirst = (userAccount, oldPwd, newPwd, tenancyId, deptId, postId, loginSource) => ssoRequest.post('/web/resetPwdFirst', {
  userAccount,
  oldPwd,
  newPwd,
  tenancyId,
  deptId,
  postId,
  loginSource
});

/**
 * 发送短信验证码
 * @param mobile string
 * @param vcodeType string
 */
export const sendWebSingle = (mobile, vcodeType) => ssoRequest.post('/web/sendWebSingle', {
  mobile,
  vcodeType
});

/**
 * 发送短信验证码
 * @param mobile string
 * @param vcodeType string
 */
export const checkVcodeLogin = (mobile, vcode, vcodeType, tenancyId, deptId, postId, loginSource) => ssoRequest.post('/web/checkVcodeLogin', {
  mobile,
  vcode,
  vcodeType,
  tenancyId,
  deptId,
  postId,
  loginSource
});
