import { baseRequest } from './index';

/**
 * 获取人员树
 * @params data Object
 * @returns MenusInfo
*/
export const selectAllOrgByParam = (data) => baseRequest.post('basic/org/selectAllOrgByParam', data);

/**
 * 拉取员工列表-部门（树）
 * @params bpoFlag int
 * @params rootOrgId int
 * @returns UserInfo
*/
export const ssoOrgTreeList = (bpoFlag, rootOrgId) => baseRequest.post('/basic/org/treeList', {bpoFlag, rootOrgId});

/**
 * 根据部门属性查询大区/分公司信息
 * @param orgType int  2 分公司 3 大区
 * @returns UserInfo
*/
export const selectOrgByOrgType = (orgType) => baseRequest.post('/basic/org/selectOrgByOrgType', {orgType});

/**
 * 根据员工姓名查询当前员工相关信息
 * @param  data Object
 * @returns MenusInfo
*/
export const queryStaffByParam = (data) => baseRequest.post('/basic/api/staff/queryStaffByParam', data);

/**
 * 全局-CRM获取字典表信息
 * @returns MenusInfo
*/
export const dictList = () => baseRequest.get('/crmapp/common/dictList');

/**
 * 根据登录人获取搜索初始化默认客户名称
 * @returns MenusInfo
*/
export const myCustWithBusOppoList = (custCn) => baseRequest.post('/crmapp/oppo/track/myCustWithBusOppoList', {custCn});

/**
 * 根据客户名称获取系统全部客户信息
 * @returns MenusInfo
*/
export const getAllCustList = (custCn) => baseRequest.post('/crmapp/customermanagement/getAllCustList', {custCn});

/**
 * 根据客户id获取商机编号
 * @params custId int
 * @params staffId int
 * @params searchTitle int
 * @returns UserInfo
*/
export const getBusOppoCodeByCustId = (data) => baseRequest.post('/crmapp/oppo/track/getBusOppoCodeByCustId', data);

/**
 * 根据部门名称查询部门信息
 * @params orgTitle string
 * @params status int
 * @returns MenusInfo
*/
export const querySingleByParam = (orgTitle, status) => baseRequest.post('/basic/api/org/querySingleByParam', {orgTitle, status});

/**
 * 当获取前登录所属体系
 * @param data
 * @returns {*}
 */
export const getOrgAll = (data) => baseRequest.post('/basic/org/getOrgAll', data);
