import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import config from '_lib/config';

// const showToastTime = Toast.$create({
//   txt: 'Loading...',
//   type: 'loading',
//   time: 0,
//   mask: true
// });
const removeToken = () => {
  store.commit('CLEAR_TOKEN');
};

const goToAuth = () => {
  if (store.state.isOA) {
    router.push({ name: 'error-oa' });
  } else if (store.state.isWeChat) {
    // 企业微信时，跳转首页重新执行自动登录逻辑
    switch (store.state.source) {
    case 'weChat':
      router.push({ name: 'home', query: { source: 'weChat' } });
      break;
    case 'weChatMaterial':
      router.push({ name: 'MaterialLibrary', query: { source: 'weChatMaterial' } });
      break;
    case 'weChatCustomer':
      router.push({ name: 'AssociatedError', query: { source: 'weChatCustomer' } });
      break;
    case 'weChatNotification':
      router.push({ name: 'Message', query: store.state.mulParameters });
      break;
    }
    // router.push({ name: 'home', query: { source: store.state.source } });

  } else {
    router.push({ name: 'sign-in', query: {redirectUrl: window.location.pathname+window.location.search} });
    // router.push({ name: 'sign-in'});
  }
};

// const loadingNeedArray = ['/crmapp/customermanagement/list', '/crmapp/customermanagement/aroundcust', '/crmapp/customermanagement/alllist', '/crmapp/weekReport/addComment', '/crmapp/custregister/list', '/crmapp/custregister/save'];
const loadingNeedArray = [];
class Http {
  constructor(baseUrl = config.BASE_URL) {
    this.baseUrl = baseUrl;
    this.requestQueue = [];
    this.loading = null;
  }

  getFixedConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      timeout: 20000
    };

    return config;
  }

  interceptors(instance) {
    instance.interceptors.request.use(
      config => {
        const loadBol = loadingNeedArray.some(
          item => config.url === item
        );

        if (loadBol) {
          Vue.$loading.show();
        }

        config.headers.token = store.state.token || store.state.accessToken;

        return config;
      },
      error => Promise.reject(error)
    );

    instance.interceptors.response.use(
      response => {
        Vue.$loading.hide();
        const { errorCode } = response.data;
        const showToast = Vue.prototype.$showToast;
        switch (errorCode) {
        case config.ERR_AUTH_EXPIRED:
          removeToken();
          showToast('身份过期，请重新登录');
          goToAuth();
          break;
        case config.ERR_NOT_LOGIN:
          removeToken();
          showToast('用户未登录');
          goToAuth();
          break;
        case config.ERR_FAIL_LOGIN:
          removeToken();
          showToast('网络异常，请重新登录');
          goToAuth();
          break;
        case config.ERR_SIGN_ELSE:
        case config.ERR_SIGN_OTHER_ELSE:
          removeToken();
          showToast('您的账号已在其他地方登录，请注意账号安全');
          goToAuth();
          break;
        default:
          // code
          break;
        }

        return response;
      },
      error => {
        const showToast = Vue.prototype.$showToast;

        if (!error.response) {
          if (error.message.includes('timeout')) {
            showToast('请求超时，请检查网络是否连接正常');
          } else {
            showToast('请求失败，请检查网络是否已连接');
          }
        } else {
          switch (error.response.status) {
          case 404:
            showToast('请求网络不存在');
            break;
          default:
            showToast('网络异常');
            break;
          }
        }

        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getFixedConfig(), options);
    this.interceptors(instance);
    return instance(options);
  }

  get(url, options = {}) {
    const instance = axios.create(this.getFixedConfig());
    this.interceptors(instance);

    return new Promise((resolve, reject) => {
      instance.get(url, options).then(
        res => {
          resolve(res.data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  post(url, data = {}, options = {}) {
    const instance = axios.create(this.getFixedConfig());
    this.interceptors(instance);

    return new Promise((resolve, reject) => {
      instance.post(url, data, options).then(
        res => {
          resolve(res.data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}

export default Http;
