const clue = {
  namespaced: true,
  state: {
    filter: {},
    aidList: []
  },
  mutations: {
    CLUE_FILTER(state, payload) {
      state.filter = { ...state.filter, ...payload };
    },
    AID_LIST(state, payload) {
      state.aidList = [...payload];
    }
  },
  getters: {
    filterObj: (state) => state.filter,
    aidList: (state) => state.aidList
  },
  actions: {

  }

};

export default clue;
