<template>
  <div
    v-show="open"
    class="joyo-loading-mum"
  >
    <div class="wrapper">
      <cube-loading :size="24" />
    </div>
    <div
      class="shadow"
      @touchmove.prevent
    />
  </div>
</template>
<script type="text/ecmascript-6">
const COMPONENT_NAME = 'loading';

export default {
  name: COMPONENT_NAME,
  data() {
    return {
      open: {
        type: Boolean,
        default: true
      }
    };
  },
  methods: {
    show() {
      // eslint-disable-next-line vue/no-mutating-props
      this.open = true;
    },
    hide() {
      // eslint-disable-next-line vue/no-mutating-props
      this.open = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.joyo-loading-mum {
  .wrapper {
    position: fixed;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 999;
    width: 24px;
    height: 24px;
    padding: 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    transform: translate(-50%, -50%);
    // background-color: $color-CCC;
    // opacity: 0.6;
  }

  .shadow {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
}
</style>
