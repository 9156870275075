import { getToken } from '_api/sign-in';
import store from '@/store';

export const getAccessToken = () => getToken().then((res) => {
  if (res.flag) {
    const { tempToken, appId	} = res.data;
    store.commit('SET_ACCESS_TOKEN', tempToken);
    store.commit('SET_APPID', appId);
  }
});
