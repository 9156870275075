const mutations = {
  SET_ACCESS_TOKEN(state, payload) {
    state.accessToken = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_EXTERBALUSERID(state, payload) {
    state.external_userid = payload;
  },
  CLEAR_TOKEN(state) {
    state.token = '';
  },
  SET_APPID(state, payload) {
    state.appId = payload;
  },
  CLEAR_APPID(state) {
    state.appId = '';
  },
  SET_ENV_OA(state) {
    state.isOA = true;
  },
  CLEAR_ENV_OA(state) {
    state.isOA = false;
  },
  SET_ENV_WECHAT(state) {
    state.isWeChat = true;
  },
  SET_ENV_SOURCE(state, payload) {
    state.source = payload;
  },
  SET_ENV_PARAMS(state, payload) {
    state.mulParameters = payload;
  },
  CLEAR_ENV_WECHAT(state) {
    state.isWeChat = false;
  },
  SET_ORG_AND_POST_LIST(state, payload) {
    state.orgAndPostList = [...payload];
  },
  CLEAR_ORG_AND_POST_LIST(state) {
    state.orgAndPostList = [];
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = { ...payload };
  },
  SET_ROLE_LIST(state, payload) {
    state.roleList = payload;
  },
  SET_POST_DATA(state, payload) {
    state.postData = payload;
  },
  CLEAR_USER_INFO(state) {
    state.userInfo = {};
  },
  CLEAR_FILTER(state, payload) {
    state[payload].filter = {};
  },
  SET_KEEPALIVE: (state, keepAlive) => {
    state.keepAlive = keepAlive;
  },
  ORG_TREE(state, payload) {
    state.orgTree = [...payload];
  },
  DEPART_TREE(state, payload) {
    state.departTree = [...payload];
  },
  DICTLIST(state, payload) {
    state.dictList = payload;
  },
  SYSTEM(state, payload) {
    state.system = payload;
  },
  GetBRANCHLIST(state, payload) {
    state.branchList = [...payload];
  }
};

export default mutations;
