const getters = {
  appId(state) {
    return state.appId;
  },
  accessToken(state) {
    return state.accessToken;
  },
  token(state) {
    return state.token;
  },
  externalUserid(state) {
    return state.external_userid;
  },
  orgAndPostList: (state) => {
    return state.userInfo.postRoleResList || [];
    // const list = [];
    // // flat，源数据用 state
    // state.orgAndPostList.forEach((item) => {
    //   item.postList.forEach((post) => {
    //     list.push({
    //       orgId: item.orgId,
    //       orgKey: item.orgKey,
    //       postCode: post.postCode,
    //       postId: post.postId,
    //       postTitle: post.postTitle
    //     });
    //   });
    // });
    // return list;
  },
  postData: state => state.postData || {},
  roleList: state => state.roleList || [],
  userInfo: (state) => state.userInfo,
  oaErrorMsg: (state) => state.oaErrorMsg,
  keepAlive: (state) => state.keepAlive,
  orgTree: (state) => state.orgTree,
  departTree: (state) => state.departTree,
  dictList: (state) => state.dictList,
  system: (state) => state.system,
  branchList: (state) => state.branchList,
  isOA: state => state.dictList,
  isWeChat: state => state.isWeChat
};

export default getters;
