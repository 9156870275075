const sign = {
  namespaced: true,
  state: {
    filter: {},
    custId: '',
    custCn: '',
    custAddress: {
      addrProvince: '',
      addrCity: '',
      addrArea: '',
      addrStreet: '',
      province: '',
      city: '',
      area: ''
    },
    originAddress: {
      addrProvince: '',
      addrCity: '',
      addrArea: '',
      addrStreet: '',
      province: '',
      city: '',
      area: ''
    },
    aidList: [],
    longitude: '',
    latitude: '',
    checkAddress: '', // 地址微调展示地址
    contactsId: '',
    contactsName: '',
    currentLocation: '',
    // crm1.9.2
    custName: '', // 地址名称
    entranceIndex: 1, // 不同进入页面的标识

  },
  mutations: {
    SET_FILTER(state, payload) {
      state.filter = { ...state.filter, ...payload };
    },
    SET_CUSTOMER(state, { id, custCn, province, city, area, addrProvince, addrCity, addrArea, addrStreet, latitude, longitude }) {
      state.custId = id;
      state.custCn = custCn;
      state.custAddress = {
        // 地址code
        addrProvince: addrProvince,
        addrCity: addrCity,
        addrArea: addrArea,
        // 地址name
        province: province,
        city: city,
        area: area,
        addrStreet: addrStreet
      };
      state.originAddress = {
        // 地址code
        addrProvince: addrProvince,
        addrCity: addrCity,
        addrArea: addrArea,
        // 地址name
        province: province,
        city: city,
        area: area,
        addrStreet: addrStreet
      };
      state.latitude = latitude;
      state.longitude = longitude;
    },
    CLEAR_CUSTOMER(state) {
      state.custId = '';
      state.custCn = '';
      state.custAddress.addrProvince = '';
      state.custAddress.addrCity = '';
      state.custAddress.addrArea = '';
      state.custAddress.province = '';
      state.custAddress.city = '';
      state.custAddress.area = '';
      state.custAddress.addrStreet = '';
      state.latitude = '';
      state.longitude = '';
      state.custName = '';
      state.checkAddress = '';
    },
    SET_CONTACT(state, { contactsId, contactsName }) {
      state.contactsId = contactsId;
      state.contactsName = contactsName;
    },
    CLEAR_CONTACT(state) {
      state.contactsId = '';
      state.contactsName = '';
    },
    SET_CHECK_ADDRESS(state, { name, address, location }) {
      state.custName = name;
      state.checkAddress = address;
      state.currentLocation = location;
    },
    CLEAR_CHECK_ADDRESS(state) {
      state.custName = '';
      state.checkAddress = '';
      state.currentLocation = '';
    },
    SET_ADDRESS(state, { province, city, area, addrProvince, addrCity, addrArea, addrStreet }) {
      state.custAddress = {
        // 地址code
        addrProvince: addrProvince,
        addrCity: addrCity,
        addrArea: addrArea,
        // 地址name
        province: province,
        city: city,
        area: area,
        addrStreet: addrStreet
      };
    },
    SET_ADDRESS_STREET(state, addrStreet) {
      state.custAddress.addrStreet = addrStreet;
    },
    AID_LIST(state, payload) {
      state.aidList = [...payload];
    },
    // crm1.9.2新增  (判断哪里进来的签到)
    SET_ENTRANCE_INDEX(state, payload) {
      state.entranceIndex = payload;
    }
  },
  getters: {
    filter: state => state.filter,
    custId: state => state.custId,
    custCn: state => state.custCn,
    aidList: state => state.aidList,
    custAddress: state => state.custAddress,
    longitude: state => state.longitude,
    latitude: state => state.latitude,
    custName: state => state.custName,
    checkAddress: state => state.checkAddress,
    entranceIndex: state => state.entranceIndex
  }
};

export default sign;
