const state = {
  appId: '', // 企业微信appId
  accessToken: '',
  token: '',
  external_userid: '', // 获取当前企业微信客户的userid
  orgAndPostList: [],
  userInfo: {},
  isOA: false,
  isWeChat: false,
  source: '', // 记录页面来源
  mulParameters: {}, // 记录消息类型所有参数跳转
  oaErrorMsg: '未配置权限',
  keepAlive: [],
  orgTree: [],
  departTree: [],
  dictList: {},
  system: '', // 所属体系
  branchList: [], // 分公司
  roleList: [],
  postData: {}
};

export default state;
