import Vue from 'vue'

import {
  Calendar,
  Collapse,
  CollapseItem,
  DatetimePicker,
  Picker,
  Popup,
  Slider
} from 'vant'

Vue.use(Calendar)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Slider)
