import Vue from 'vue';

import './cube-ui';
import './vant-ui';
import './registerServiceWorker';
import '_lib/register-components.js';
import '@/assets/css/iconPark';
// eslint-disable-next-line import/named
import { Toast } from 'cube-ui';
import fastClick from 'fastclick';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

import { version } from '../package.json';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/css/iconfont.css';

fastClick.attach(document.body);
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import loading from '_c/loading-joyo/index';
Vue.use(loading);

import loadingG from '_c/loading-generally/index';
Vue.use(loadingG);

import Vconsole from 'vconsole';
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'pre') {
  const vconsole = new Vconsole();
  Vue.use(vconsole);
}

// import {setRem} from '@/lib/until';
// setRem(375);
// echarts
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://9d2141de7f5d4726bd3923e698ff7eb8@sentry.joyobpo.net/6',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    environment: process.env.NODE_ENV,
    release: `mobile-crm_${version}`,
    tracesSampleRate: 1.0,
    ignoreErrors: [],
  });
  Sentry.setContext('localSotrage', {
    token: store.state.token || store.state.accessToken
  });
}

Vue.config.productionTip = false;

Vue.prototype.$showToast = (txt, type = 'txt', time = 1500) => {
  Toast.$create({ txt, type, time }, true).show();
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
