import Vue from 'vue';
import VueRouter from 'vue-router';

import { handleOALogin } from '../lib/oa';
import { getQueryStringArgs, isOA, isOAEnter, isWeChat, isWeChatCustomer, isWeChatMaterial, isWeChatNotification } from '../lib/until';
import { handleWeChatLogin } from '../lib/wechat';
import store from '../store';
import routes from './router';
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

// 每次 oa 进来强制登录
if (isOAEnter()) {
  store.commit('CLEAR_TOKEN');
}

// 每次 企业微信 进来强制登录
if (isWeChat() || isWeChatMaterial() || isWeChatCustomer()) store.commit('CLEAR_TOKEN');
// if (isWeChatCustomer()) geWxAgentConfig();
// if (isWeChatMaterial()) geWxAgentConfigMaterial();

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.path === '/addedit-business' && to.query.businessId) {
    document.title = '编辑商机';
  } else if (to.path === '/addedit-business' && !to.query.businessId) {
    document.title = '添加商机';
  } else if (to.path === '/addedit-follow' && to.query.followId) {
    document.title = '编辑跟进';
  } else if (to.path === '/addedit-follow' && !to.query.followId) {
    document.title = '添加跟进';
  } else if (to.path === '/addedit-contact' && to.query.contactId) {
    document.title = '编辑联系人';
  } else if (from.name === 'AddeditFollow' && to.name !== 'follow-tree') {
    store.commit('follow/DELETE_ACCOMPANY_LIST', []);
    // store.commit('SET_KEEPALIVE', []);
  }

  if (store.state.token) {
    if (to.path === '/associated-error' || to.path === '/material-library' || to.path === '/message') {
      next();
    } else {
      if (isWeChatCustomer()) {
        next('/associated-error');
      } else if (isWeChatMaterial()) {
        next('/material-library');
      } else if (isWeChatNotification()) {
        next();
      } else {
        next();
      }
    }
  } else {
    if (isOA()) {
      store.commit('SET_ENV_OA');
      if (to.name === 'error-oa') {
        next();
      } else {
        handleOALogin(next);
      }
    } else if (isWeChatCustomer() || isWeChatMaterial() || isWeChatNotification() || isWeChat() || store.state.isWeChat) {
      store.commit('SET_ENV_WECHAT');
      store.commit('SET_ENV_SOURCE', getQueryStringArgs().source || store.state.source); // 存下访问来源
      if (isWeChatNotification()) store.commit('SET_ENV_PARAMS', getQueryStringArgs() || store.state.mulParameters); // 存 query 参数
      if (to.name === 'error-wechat') {
        next();
      } else {
        handleWeChatLogin(next);
      }
    } else {
      if (to.path === '/sign_in' || to.path === '/first-reset' || to.path === '/authentication') {
        next();
      } else {
        // 未登录过的记录当前打开页面 登录完之后 重新去跳转
        // next({path:'/sign_in', query: {redirectUrl: to.fullPath}});
        next({ path: '/sign_in', query: { redirectUrl: to.fullPath }});
      }
    }
  }
});

export default router;
