import Vue from 'vue';

import { defaultMaxListeners } from 'stylus/lib/renderer';
var sha1 = require('js-sha1');

export const findIndex = (ary, fn) => {
  if (ary.findIndex) {
    return ary.findIndex(fn);
  }
  /* istanbul ignore next */
  let index = -1;
  /* istanbul ignore next */
  ary.some(function(item, i, ary) {
    const ret = fn.call(this, item, i, ary);
    if (ret) {
      index = i;
      return ret;
    }
  });
  /* istanbul ignore next */
  return index;
};

export const ease = {
  // easeOutQuint
  swipe: {
    style: 'cubic-bezier(0.23, 1, 0.32, 1)',
    fn: function(t) {
      return 1 + (--t * t * t * t * t);
    }
  },
  // easeOutQuard
  swipeBounce: {
    style: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    fn: function(t) {
      return t * (2 - t);
    }
  },
  // easeOutQuart
  bounce: {
    style: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    fn: function(t) {
      return 1 - (--t * t * t * t);
    }
  }
};

/*
 * 防抖
 * @param {function} fn 需执行的函数
 * @param {Number} delay 延迟毫秒数
 */
let timer = null;
export const debounce = (fn, delay = 500) => function() {
  const ctx = this;
  const args = arguments;
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    timer = null;
    fn.apply(ctx, args);
  }, delay);
};
/**
 * 节流
 * @param {function} fn 需执行的函数
 * @param {Number} delay 延迟毫秒数
 */
export const throttle = (fn, interval = 500) => {
  let last = null;
  let timer = null;
  return function() {
    const ctx = this;
    const args = arguments;
    const now = new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(ctx, args);
      }, interval);
    } else {
      last = now;
      fn.apply(ctx, args);
    }
  };
};

/**
 * 解析 url 参数
 */
export const getQueryStringArgs = () => {
  const qs = location.search.length > 0 ? location.search.substring(1) : '';
  const args = {};
  const items = qs.length > 0 ? qs.split('&') : [];
  let item = null;
  let name = null;
  let value = null;
  for (let i = 0; i < items.length; i++) {
    item = items[i].split('=');
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args;
};

/**
 * 解析 url 参数拼成字符串
*/

export const getObjToString = (obj) => {
  let paramsStr = '';
  for (const i in obj) paramsStr += `${i}=${obj[i]}&`;
  const mulParamsString = paramsStr.substring(0, paramsStr.length - 1);
  return mulParamsString;
};

/**
 * oa 环境
 */
export const isOA = () => getQueryStringArgs().source === 'oa';

/**
 * weChat 环境
 */
export const isWeChat = () => getQueryStringArgs().source === 'weChat';

/**
 * WeChat 跳转素材中心 环境
 */
export const isWeChatMaterial = () => getQueryStringArgs().source === 'weChatMaterial';

/**
 * WeChat 跳转企微客户 环境
 */
export const isWeChatCustomer = () => getQueryStringArgs().source === 'weChatCustomer';

/**
 * WeChat 跳转企微消息 环境
 */
export const isWeChatNotification = () => getQueryStringArgs().source === 'weChatNotification';

/**
 * oa 打开页面
 * @returns {Boolean}
 */
export const isOAEnter = () => {
  const { source, account, password } = getQueryStringArgs();
  return source && account && password;
};

/**
 * 去除第一个'/'之前的内容
*/
export const regular = (str) => {
  const reg = str.replace(/^[^/]+\//, ' ');
  return reg;
};

/**
 * 对象转数组
*/
export const toArrays = (obj) => {
  const arr = [];
  Object.keys(obj).forEach(item => {
    arr.push(obj[item]);
  });
  return arr;
};

/**
 * 截取
*/

export const verdict = (str = '', len = 10) => {
  let string = '';
  if (str && str.length >= len) {
    string = `${str.substr(0, len)}…`;
  } else {
    string = str;
  }
  return string;
};

/**
 * 转换后台返回人员树格式
 * @param [Array]
 * @returns array
 */
export const changeNewTreeKey = (list, showHidden = false, duplicate = true) => {
  list.forEach(item => {
    item.children = Array.isArray(item.childOrgList) ? item.childOrgList.concat() : [];
    delete item.childOrgList;
    delete item.staffcdLength;
    delete item.staffcdPrefix;
    delete item.staffcdSeq;
    delete item.status;
    delete item.tenancyFlag;
    delete item.updateTime;
    delete item.updateUid;
    delete item.manageFlag;
    delete item.ondutyNum;
    delete item.orgCode;
    delete item.orgLevel;
    delete item.orgManagerId;
    delete item.orgRemark;
    if (item.staffResDtoList) {
      item.staffResDtoList.forEach(item2 => {
        item.children.push({
          id: item2.id,
          orgTitle: item2.workStatus === 3 ? `${item2.realName}(离职)` : item2.realName,
          orgId: item2.orgId,
          workStatus: item2.workStatus
        });
      });
      delete item.staffResDtoList;
    }

    // if (Array.isArray(item.children) && item.children.length === 0) {
    //   if (duplicate) {
    //     item.id += Math.floor(Math.random() * 90) + 10;
    //   }
    //   delete item.children;
    // }
    if (Array.isArray(item.children) && item.children.length > 0) {
      for (let i = 0; i < item.children.length; i++) {
        if (showHidden && item.children[i].workStatus === 3) {
          delete item.children[i];
        }
      }
      changeNewTreeKey(item.children, showHidden, duplicate);
    }
  });
  return list;
};

export const subStr = (val, len = 2) => {
  return val.substring(0, val.length - len);
};

/**
 * 删除children
 * @param [Array]
 * @returns array
 */

export const deleteTree = (list) => {
  list.map(item => {
    if (item.children.length === 0) {
      delete item.children;
    } else {
      deleteTree(item.children);
    }
  });
  return list;
};

/**
 * 根据key获取对应的value
 * @param [list,key] list 要转换的键值对列表 key 具体的key值
 * @returns string key对应的value值
 */
export const findValueByKey = (arr = [], key) => {
  let value = '';
  arr.forEach(item => {
    if (item.value === key) {
      value = item.text;
    }
  });
  return value;
};

/**
 * 判断月日长度
 * @param [date] 日期
 * @returns 08
 */
export const substrDate = (date) => {
  let value = '';
  if ((date + '').length < 2) {
    value = `0${date}`;
  } else {
    value = date;
  }
  return value;
};

/**
 * 获取星期
 * @returns week
 */

export const getWeekDate = () => {
  var now = new Date();
  var day = now.getDay();
  // eslint-disable-next-line no-array-constructor
  var weeks = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
  var week = weeks[day];
  return week;
};

/**
 * 获取年月日
 * @returns 2020年9月9日
 */

export const getYearMonthDate = (val) => {
  var date = val || new Date();
  const Y = date.getFullYear() + '年';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
  const D = date.getDate() + '日';
  return {
    Y,
    M,
    D
  };
};

/**
 * 获取本月第几周
 * @returns 九月第2周
 */

export const getMonthWeek = (a, b, c) => {
  // eslint-disable-next-line radix
  const date = new Date(a, parseInt(b) - 1, c);
  let w = date.getDay();
  const d = date.getDate();
  if (w === 0) {
    w = 7;
  }
  var config = {
    getMonth: date.getMonth() + 1,
    getYear: date.getFullYear(),
    getWeek: Math.ceil((d + 6 - w) / 7)
  };
  return config;
};

/**
 * 数字月份转中文月份
 * @returns 九月第2周
 */

export const numChangeChinese = (num) => {
  const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const chineseArr = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
  for (let i = 0; i < numArr.length; i++) {
    if (num === numArr[i]) {
      return chineseArr[i];
    }
  }
};

/**
 * 自定义搜索正则
 * @returns 九月第2周
 */

export const generReg = (val) => {
  return new RegExp(`(.*)(${val.split('').join(')(.*)(')})(.*)`, 'i');
};

// 判断距离是否小于1200米，是则返回true
export function compareDistance(lnglat, origin) {
  const myDistance = lnglat.distance(origin);
  if (myDistance < 200) {
    return true;
  }
  return false;
}

/**
 * 状态匹配颜色
 * @returns color
 */
const valueColor = [
  // sign
  { val: '正常', color: '#00A4FF' },
  { val: '无状态', color: '#999999', background: '#F5F5F7' },
  { val: '异常', color: '#F7B321' },
  { val: '未填写', color: '#F33509' },
  { val: '已填写', color: '#999999' },
  { val: '查看', color: '#999999' },
  // clueStatus
  { val: '未跟进', color: '#F7B321', background: '#FFEFDE', newColor: '#C6C7CC' },
  { val: '跟进中', color: '#F7B321', background: '#FFEFDE', newColor: '#FF7B00' },
  { val: '转客户', color: '#00A4FF' },
  { val: '失效', color: '#999999' },
  { val: '已签约', color: '#00A4FF', background: 'rgba(0, 164, 255, 0.1)', newColor: '#00CA00' },
  // cooperationStatus
  { val: '新开发', color: '#F7B321' },
  { val: '已合作', color: '#00A4FF' },
  // oppoStatus
  { val: '暂无意向', color: '#999999', background: '#F5F5F7', newColor: '#C6C7CC' },
  { val: '服务中', color: '#00A4FF', background: 'rgba(0, 164, 255, 0.1)', newColor: '#FF7B00' },
  { val: '已终止', color: '#999999', background: '#F5F5F7', newColor: '#FF585C' },
  { val: '锁定', color: '#F7B321', background: '#FFEFDE', newColor: '#FF585C' },
  // contacts
  { val: '未知', color: '#00A4FF' },
  { val: '男', color: '#00A4FF' },
  { val: '女', color: '#00A4FF' },
  // workreport
  { val: '已读', color: '#CCCCCC' },
  { val: '未读', color: '#F7B321' }
];

export const matchingColor = (val) => {
  for (let i = 0; i < valueColor.length; i++) {
    if (val === valueColor[i].val) {
      return {
        'color': valueColor[i].color
      };
    }
  }
};

export const matchColor = (val) => {
  for (let i = 0; i < valueColor.length; i++) {
    if (val === valueColor[i].val) {
      return {
        'color': valueColor[i].color,
        'backgroundColor': valueColor[i].background
      };
    }
  }
};

export const matchColorBack = (val) => {
  for (let i = 0; i < valueColor.length; i++) {
    if (val === valueColor[i].val) {
      return {
        'backgroundColor': valueColor[i].newColor
      };
    }
  }
};

/**
 * 对比时间大小
 * @returns Boolean
 */

export var comparTime = (beforval, afeterval) => {
  beforval = beforval.replace('/-/g', '/');
  afeterval = afeterval.replace('/-/g', '/');
  beforval = new Date(beforval).getTime();
  afeterval = new Date(afeterval).getTime();
  if (beforval >= afeterval) {
    return true;
  } else {
    return false;
  }
};

/**
 * 金额格式化显示
 * @param [num] 12300.45
 * @returns string 12,300.45
 */

export const toMoney = (num = 0, isInput = false, isNumTwo = true) => {
  if (isInput && num === '') {
    num = '';
    return;
  }
  if (isNaN(num)) {
    Vue.prototype.$showToast('金额中含有不能识别的字符');
    return;
  }
  if (num === null) {
    num = 0;
  }
  num = typeof num === 'string' ? parseFloat(num) : num; // 判断是否是字符串如果是字符串转成数字
  num = num.toFixed(2); // 保留两位
  num = parseFloat(num); // 转成数字
  num = num.toLocaleString(); // 转成金额显示模式
  // 判断是否有小数
  if (isNumTwo) {
    if (num.indexOf('.') === -1) {
      num = num + '.00';
    } else {
      num = num.split('.')[1].length < 2 ? num + '0' : num;
    };
  }
  return num; // 返回的是字符串23,245.12保留2位小数
};

/**
 * 金额千分符格式转换为数字
 * @param [num] 12,300.45
 * @returns string 12300.45
 */

export const toNum = (money) => {
  const num = money.toString().split(',').join('');
  return num;
};

/**
 * 时间戳转年月日时分秒
 * @param [timestamp] 1230045 时间戳
 * @returns string 年月日
 */

export const timestampToTime = (timestamp) => {
  const time = {
    Y: '',
    M: '',
    D: '',
    h: '',
    m: '',
    s: ''
  };
  var date = new Date(timestamp * 1000);// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  time.Y = date.getFullYear();
  time.M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  time.D = date.getDate() + ' ';
  time.h = date.getHours();
  time.m = date.getMinutes();
  time.s = date.getSeconds();
  return time;
};

/**
 * 通过当前时间获取当前周开始时间和结束时间
 * @param val 当前时间
 * @returns string 时间区间
 */

export const getWeekInterval = (val) => {
  const timestamps = (timestamp) => {
    var date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    return Y + M + D;
  };
  var todayDay = new Date(val);
  const todayOfWeek = todayDay.getDay();
  let spendDay = 1;
  if (todayOfWeek !== 0) {
    spendDay = 7 - todayOfWeek;
  }
  const startTimeNum = todayDay.valueOf() - (6 - spendDay) * 86400000;
  const endTimeNum = todayDay.valueOf() + spendDay * 86400000;
  const startTimeStr = timestamps(startTimeNum); // 时间戳转字符串
  const endTimeStr = timestamps(endTimeNum);
  return {
    startTimeStr,
    endTimeStr
  };
};

export const ModalHelper = (bodyCls) => {
  var scrollTop = null;
  return {
    afterOpen: function() {
      scrollTop = document.scrollingElement.scrollTop;
      document.body.classList.add(bodyCls);
      document.body.style.top = -scrollTop + 'px';
    },
    beforeClose: function() {
      document.body.classList.remove(bodyCls);
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop;
    }
  };
};

/**
 * 根据name截取浏览器地址栏参数
 * @param name
 * @return value
 */
export function getUrlParam(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return '';
}

export function setRem(baseWidth = 750) {
  const dpr = window.devicePixelRatio;
  const currentWidth = document.documentElement.clientWidth;
  let remSize = 0;
  let scale = 0;
  scale = currentWidth / baseWidth;
  remSize = baseWidth / 10;
  remSize = remSize * scale;
  document.documentElement.style.fontSize = remSize + 'px';
  document.documentElement.setAttribute('data-dpr', `${dpr}`);
}

/**
 * 根据商机跟进进程返回比例
 * @param key
 * @returns '\ue741'
 */

const reliable = [
  { key: 2, val: '靠谱度20%-需求沟通', proportion: [20, 80], icon: '\ue6d8' },
  { key: 4, val: '靠谱度40%-方案制作/洽淡', proportion: [40, 60], icon: '\ue6d6' },
  { key: 5, val: '靠谱度50%-项目评估', proportion: [50, 50], icon: '\ue6da' },
  { key: 6, val: '靠谱度60%-报价/议价', proportion: [60, 40], icon: '\ue6d9' },
  { key: 7, val: '靠谱度70%-商务合同修订', proportion: [70, 30], icon: '\ue6dc' },
  { key: 8, val: '靠谱度80%-商务合同签订', proportion: [80, 20], icon: '\ue6d7' },
  { key: 10, val: '靠谱度100%-项目启动', proportion: [0, 100], icon: '\ue6db' },
  { key: 11, val: '靠谱度0%-丢单', proportion: [100, 0], icon: '\ue6d5' }
];

export const reliableMatch = (val) => {
  for (let i = 0; i < reliable.length; i++) {
    if (val === reliable[i].key) {
      return reliable[i].icon;
    }
  }
};

/**
 * 签名算法插件
 * @param key
 * @returns
 */

export const getSha1 = (val) => {
  return sha1(val);
};

/**
 * 截取名字两位
 * @param key
 * @returns string
 */

export const interceptName = (val) => {
  return val.substring(val.length - 2, val.length);
};

/**
 * 页面禁用Ios 上下弹性滚动
 * @param querySelector
 */
export const preventDefaultd = (val) => {
  document.querySelector(val).addEventListener('touchmove', function(e) {
    e.preventDefault(); // 阻止默认的处理方式(阻止下拉滑动的效果)
  }, { passive: false });
};

/**
 * 计算两时间相差天数
 * @param querySelector
 */

export const datedifference = (sDate1, sDate2) => { // sDate1和sDate2是2006-12-18格式
  let dateSpan = '';
  let iDays = '';
  sDate1 = Date.parse(sDate1);
  sDate2 = Date.parse(sDate2);
  dateSpan = sDate2 - sDate1;
  dateSpan = Math.abs(dateSpan);
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
  return iDays;
};

/**
 * 计算两时间相差小时或天数
 * @param querySelector
 */

export const differenceHour = (sTime) => {
  const startTime = new Date(sTime); // 开始时间
  const endTime = new Date(); // 结束时间
  const usedTime = endTime - startTime; // 相差的毫秒数
  const days = Math.floor(usedTime / (24 * 3600 * 1000)); // 计算出天数
  const leavel = usedTime % (24 * 3600 * 1000); // 计算天数后剩余的时间
  const hours = Math.floor(leavel / (3600 * 1000)); // 计算剩余的小时数
  const leavel2 = leavel % (3600 * 1000); // 计算剩余小时后剩余的毫秒数
  const minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数
  // return days + '天' + hours + '时' + minutes + '分';
  return {
    days,
    hours,
    minutes
  };
};

/**
 * 判断是电脑(pc)访问还是手机(mobile)访问
 * @param querySelector
 */

export const isPcMobile = () => {
  if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
    return 1;
  } else {
    return 2;
  }
};

/**
 * 判断系统 是ios还是 android
*/

export const appSource = () => {
  // Navigator 对象包含有关浏览器的信息
  // userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值。

  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return "ios";
  } else {
    return "android";
  }
}

/**
 * 转换时间搁格式
 * return YYYY-MM-DD
*/

export const formatDateTime = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;// 注意这个“+1”
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + m + '-' + d;
};
