const tree = {
  namespaced: true,
  state: {
    filter: {},
    aidList: [],
    treeId: '',
    time: 0
  },
  mutations: {
    SET_FILTER(state, payload) {
      state.filter = { ...state.filter, ...payload };
    },
    AID_LIST(state, payload) {
      state.aidList = [...payload];
    },
    SET_CUR_TREE_ID(state, payload) {
      state.treeId = payload
    },
    SET_TIME(state, payload) {
      state.time = payload
    }
  },
  getters: {
    filterObj: (state) => state.filter,
    aidList: (state) => state.aidList,
    curTreeId: state => state.treeId,
    timeNum: state => state.time
  },
  actions: {

  }

};

export default tree;
