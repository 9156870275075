export default [
  {
    path: '/',
    name: 'index',
    redirect: {
      name: 'home'
    },
    component: () => import('@/views/index/index.vue'),
    meta: {
      title: 'CRM'
    },
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/home/home.vue'),
      meta: {
        title: 'CRM',
        index: 1
      }
    }]
  },
  {
    path: '/clue-manage',
    name: 'ClueManage',
    component: () => import('@/views/clue/clue-manage.vue'),
    meta: {
      title: '线索管理',
      index: 2
    }
  },
  {
    path: '/clue-filter/:i',
    name: 'ClueFilter',
    component: () => import('@/views/clue/clue-filter.vue'),
    meta: {
      title: '线索筛选',
      index: 3
    }
  },
  {
    path: '/clue-details/:clueId/:type',
    name: 'ClueDetails',
    component: () => import('@/views/clue/clue-details.vue'),
    meta: {
      title: '线索详情',
      index: 3
    }
  },
  {
    path: '/clue-follow/:clueId/:clueName',
    name: 'clue-follow',
    component: () => import('@/views/clue/clue-follow.vue'),
    meta: {
      title: '线索跟进',
      index: 4
    }
  },
  {
    path: '/cust-manage',
    name: 'CustManage',
    component: () => import('@/views/cust/cust-manage.vue'),
    meta: {
      title: '客户管理',
      index: 2
    }
  },
  {
    path: '/cust-filter/:i',
    name: 'cust-filter',
    component: () => import('@/views/cust/cust-filter.vue'),
    meta: {
      title: '客户筛选',
      index: 3
    }
  },
  {
    path: '/cust-details/:custId/:type/:aid',
    name: 'cust-details',
    component: () => import('@/views/cust/cust-details.vue'),
    meta: {
      title: '客户详情',
      index: 3
    }
  },
  {
    path: '/cust-edit/:custId',
    name: 'cust-edit',
    component: () => import('@/views/cust/cust-edit.vue'),
    meta: {
      title: '编辑客户',
      index: 4
    }
  },
  {
    path: '/cust-add',
    name: 'cust-add',
    component: () => import('@/views/cust/cust-add.vue'),
    meta: {
      title: '添加客户',
      index: 5
    }
  },
  {
    path: '/business-manage',
    name: 'BusinessManage',
    component: () => import('@/views/business/business-manage.vue'),
    meta: {
      title: '商机管理',
      index: 2
    }
  },
  {
    path: '/business-filter/:i',
    name: 'business-filter',
    component: () => import('@/views/business/business-filter.vue'),
    meta: {
      title: '商机筛选',
      index: 3
    }
  },
  {
    path: '/business-details/:busId/:type/:aid',
    name: 'BusinessDetails',
    component: () => import('@/views/business/business-details.vue'),
    meta: {
      title: '商机详情',
      index: 3
    }
  },
  {
    path: '/addedit-business',
    name: 'AddeditBusiness',
    component: () => import('@/views/business/addedit-business.vue'),
    meta: {
      title: '添加商机',
      index: 5
    }
  },
  {
    path: '/add-sign',
    name: 'AddSigns',
    component: () => import('@/views/sign/add-signs.vue'),
    meta: {
      title: '添加签到',
      index: 4
    }
  },
  {
    path: '/sign-manage',
    name: 'SignManage',
    component: () => import('@/views/sign/sign-manages.vue'),
    meta: {
      title: '签到管理',
      index: 2
    }
  },
  {
    path: '/edit-cust-address/:id/:custCn/:index',
    name: 'EditCustAddress',
    component: () => import('@/views/sign/edit-cust-address.vue'),
    meta: {
      title: '修改客户地址',
      index: 5
    }

  },
  {
    path: '/select-customer',
    name: 'select-customer',
    component: () => import('@/views/sign/select-customer.vue'),
    meta: {
      title: '拜访客户',
      index: 5
    }
  },
  {
    path: '/adjust-address',
    name: 'adjust-address',
    component: () => import('@/views/sign/adjust-address.vue'),
    meta: {
      title: '打卡地址',
      index: 6
    }
  },
  {
    path: '/position-address',
    name: 'position-address',
    component: () => import('@/views/sign/adjust-address.vue'),
    meta: {
      title: '打卡地址',
      index: 6
    }
  },
  {
    path: '/sign-filter/:i',
    name: 'SignFilter',
    component: () => import('@/views/sign/sign-filter.vue'),
    meta: {
      title: '签到筛选',
      index: 3
    }
  },
  {
    path: '/sign-details/:id/:type/:aid',
    name: 'sign-details',
    component: () => import('@/views/sign/sign-details.vue'),
    meta: {
      title: '签到详情',
      index: 3
    }
  },
  {
    path: '/follow-manage',
    name: 'FollowManage',
    component: () => import('@/views/follow/follow-manage.vue'),
    meta: {
      title: '跟进管理',
      index: 2
    }
  },
  {
    path: '/follow-filter/:i',
    name: 'follow-filter',
    component: () => import('@/views/follow/follow-filter.vue'),
    meta: {
      title: '跟进筛选',
      index: 3
      // keepAlive: true // 需要被缓存
    }
  },
  {
    path: '/follow-details/:followId/:type?',
    name: 'follow-details',
    component: () => import('@/views/follow/follow-details.vue'),
    meta: {
      title: '跟进详情',
      index: 3
    }
  },
  {
    path: '/addedit-follow',
    name: 'AddeditFollow',
    component: () => import('@/views/follow/addedit-follow.vue'),
    meta: {
      title: '添加跟进',
      index: 5
    },
    children: [{
      path: 'tree/:routerName?/:typeName?',
      name: 'follow-tree',
      component: () => import('@/views/orgTree/tree.vue'),
      meta: {
        title: '权限树',
        index: 6
      }
    }]
  },
  {
    path: '/contacts-manage',
    name: 'ContactsManage',
    component: () => import('@/views/contacts/contacts-manage.vue'),
    meta: {
      title: '联系人管理',
      index: 2
    }
  },
  {
    path: '/contacts-filter/:i',
    name: 'contacts-filter',
    component: () => import('@/views/contacts/contacts-filter.vue'),
    meta: {
      title: '联系人筛选',
      index: 3
    }
  },
  {
    path: '/addedit-contact',
    name: 'addedit-contact',
    component: () => import('@/views/contacts/addedit-contact.vue'),
    meta: {
      title: '添加联系人',
      index: 5
    }
  },
  {
    path: '/contacts-details/:contactId/:type',
    name: 'contacts-details',
    component: () => import('@/views/contacts/contacts-details.vue'),
    meta: {
      title: '联系人详情',
      index: 3
    }
  },
  {
    path: '/workreport-manage',
    name: 'WorkreportManage',
    component: () => import('@/views/workreport/workreport-manage.vue'),
    meta: {
      title: '工作报告',
      index: 2
    }
  },
  {
    path: '/workreport-filter/:i',
    name: 'workreport-filter',
    component: () => import('@/views/workreport/workreport-filter.vue'),
    meta: {
      title: '报告筛选',
      index: 3
    }
  },
  {
    path: '/workreport-details/:reportId/:type',
    name: 'WorkreportDetails',
    component: () => import('@/views/workreport/workreport-details.vue'),
    meta: {
      title: '报告详情',
      index: 3
    }
  },
  {
    path: '/add-comments/:reportId',
    name: 'AddComments',
    component: () => import('@/views/workreport/add-comments.vue'),
    meta: {
      title: '添加评论',
      index: 4
    }
  },
  {
    path: '/file-download',
    name: 'FileDownLoad',
    component: () => import('@/views/file-download/index.vue'),
    meta: {
      title: '文件下载',
      index: 4
    }
  },
  {
    path: '/tree/:routerName?/:typeName?/:id?/:sourceOrgId?/:sourceUserId?',
    name: 'tree',
    component: () => import('@/views/orgTree/tree.vue'),
    meta: {
      title: '组织架构',
      index: 10
    }
  },
  {
    path: '/wx-cust-details',
    name: 'wx-cust-details',
    component: () => import('@/views/wx/cust'),
    meta: {
      title: '个人信息',
      index: 3
    }
  },
  {
    path: '/sign_in',
    name: 'sign-in',
    component: () => import('../views/sign-in/sign-in.vue'),
    meta: {
      title: '登录',
      index: 0,
      redirectPage: ''
    }
  },
  {
    path: '/first-reset',
    name: 'first-reset',
    component: () => import('../views/sign-in/first-reset.vue'),
    meta: {
      title: '修改密码',
      index: 1
    }
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../views/sign-in/authentication.vue'),
    meta: {
      title: '身份验证',
      index: 1
    }
  },
  {
    path: '/select-identity',
    name: 'select-identity',
    component: () => import('../views/sign-in/select-post.vue'),
    meta: {
      title: '选择岗位',
      index: 2
    }
  },
  {
    path: '/select-role',
    name: 'select-role',
    component: () => import('../views/sign-in/select-role.vue'),
    meta: {
      title: '选择角色',
      index: 3
    }
  },
  {
    path: '/switch-identity',
    name: 'switch-identity',
    component: () => import('../views/sign-in/switch-identity.vue'),
    meta: {
      title: '切换',
      index: 2
    }
  },
  {
    path: '/error-oa',
    name: 'error-oa',
    component: () => import('../views/error/error-oa.vue'),
    meta: {
      title: '暂无权限',
      index: 2
    }
  },
  {
    path: '/error-wechat',
    name: 'error-wechat',
    component: () => import('../views/error/error-wechat.vue'),
    meta: {
      title: '暂无权限',
      index: 2
    }
  },
  // 关于企业微信 素材库和客户管理
  {
    path: '/material-library',
    name: 'MaterialLibrary',
    component: () => import('@/views/material-library/index.vue'),
    meta: {
      title: '素材库',
      index: 1
    }
  },
  {
    path: '/associated-error',
    name: 'AssociatedError',
    component: () => import('@/views/qyCust/associated-error'),
    meta: {
      title: '',
      index: 0
    }
  },
  {
    path: '/associated-page',
    name: 'AssociatedPage',
    component: () => import('@/views/qyCust/associated-page'),
    meta: {
      title: '暂未关联企业',
      index: 1
    }
  },
  {
    path: '/associated-companies',
    name: 'AssociatedCompanies',
    component: () => import('@/views/qyCust/associated-companies'),
    meta: {
      title: '关联企业',
      index: 2
    }
  },
  {
    path: '/add-enterprise',
    name: 'AddEnterprise',
    component: () => import('@/views/qyCust/add-enterprise.vue'),
    meta: {
      title: '新增企业',
      index: 3
    }
  },
  {
    path: '/qycust-detail',
    name: 'QycustDetail',
    component: () => import('@/views/qyCust/qycust-detail.vue'),
    meta: {
      title: '客户详情',
      index: 4
    }
  },
  // 关于消息提示
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/message/index.vue'),
    meta: {
      title: '',
      index: 0
    }
  },
  {
    path: '/message/message-contract',
    name: 'MessageContract',
    component: () => import('@/views/message/message-contract.vue'),
    meta: {
      title: '合同续签提醒',
      index: 3
    }
  },
  {
    path: '/message/message-follow',
    name: 'MessageFollow',
    component: () => import('@/views/message/message-follow.vue'),
    meta: {
      title: '商机跟进提醒',
      index: 3
    }
  },
  {
    path: '/message/message-cust',
    name: 'MessageCust',
    component: () => import('@/views/message/message-cust.vue'),
    meta: {
      title: '工商变更提醒',
      index: 3
    }
  },
  {
    path: '/message/message-group',
    name: 'MessageGroup',
    component: () => import('@/views/message/message-group.vue'),
    meta: {
      title: '客户集团号审核提醒',
      index: 3
    }
  },
  // 客户工商变更
  {
    path: '/cust/cust-change',
    name: 'CustChange',
    component: () => import('@/views/cust/cust-change.vue'),
    meta: {
      title: '客户名称变更',
      index: 3
    }
  },
  {
    path: '/cust/upload-custfile',
    name: 'UploadCustfile',
    component: () => import('@/views/cust/upload-custfile.vue'),
    meta: {
      title: '客户名称变更',
      index: 3
    }
  },
  {
    path: '/cust/custChange-sucess',
    name: 'CustChangeSucess',
    component: () => import('@/views/cust/custChange-sucess.vue'),
    meta: {
      title: '客户名称变更',
      index: 4
    }
  },
  // 提成分成确认
  {
    path: '/contract-divided',
    name: 'ContractDivided',
    component: () => import('@/views/commission/contract-divided.vue'),
    meta: {
      title: '提成分成确定',
      index: 3
    }
  },

  {
    path: '/stutes-page',
    name: 'StutesPage',
    component: () => import('@/views/error/stutes-page.vue'),
    meta: {
      title: '提成分成',
      index: 4
    }
  }

];

