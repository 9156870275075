const workreport = {
  namespaced: true,
  state: {
    filter: {},
    aidList: [],
    fileDownLoad: {
      fileUrl: '',
      fileName: ''
    }
  },
  mutations: {
    WORKREPORT_FILTER(state, payload) {
      state.filter = { ...state.filter, ...payload };
    },
    AID_LIST(state, payload) {
      console.log(payload);
      state.aidList = [...payload];
    },
    FILE_DOWN_LOAD(state, payload) {
      state.fileDownLoad = { ...payload };
    }
  },
  getters: {
    filterObj: (state) => state.filter,
    aidList: (state) => state.aidList,
    fileDownLoad: (state) => state.fileDownLoad
  }

};

export default workreport;
