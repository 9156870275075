<template>
  <transition
    name="fade"
  >
    <section v-show="show">
      <div class="loading-css">
        <img
          :src="variable()"
          alt=""
        >
        <span class="loading-text">加载中...</span>
      </div>
    </section>
  </transition>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '正在载入...'
    }
  },
  data() {
    return {
      imgs: require('../../assets/img/yoyo.webp')
    };
  },
  methods: {
    variable() {
      return `https://frontend-${process.env.VUE_APP_CURRENTMODE === 'production' ? 'prod' : 'test'}-1256919685.cos.ap-shanghai.myqcloud.com/loading/yoyo.webp`;
      // return process.env.VUE_APP_CURRENTMODE === 'development' ? this.imgs : `${process.env.VUE_APP_CLIENT_URL}/assets/img/yoyo.webp`;
      // return this.imgs;
    }
  }
};
</script>
<style scoped lang="css">
/* .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}*/
section {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}
.loading-css{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cube-loading {
  align-self: center;
  margin-bottom: 10px;
}
.loading-text{
  color: #999BA3;
  font-size: 14px;
}
img{
  width:60px;
}
</style>
