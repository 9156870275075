import Vue from 'vue';
import store from '@/store';
import { getQueryStringArgs, isWeChatMaterial, isWeChatCustomer, isWeChatNotification, getObjToString } from './until';
import { login } from '../api/sign-in';
import { getAccessToken } from './common';
import config from '_lib/config';

const setToken = token => store.commit('SET_TOKEN', token);
const clearToken = token => store.commit('CLEAR_TOKEN', token);
const setUserInfo = data => store.commit('SET_USER_INFO', data);
const setPostList = list => store.commit('SET_ORG_AND_POST_LIST', list);
// let orgId = '';
// let postId = '';
const handleWechatLoginError = (error, next) => {
  next({ name: 'error-wechat', query: { er: String(error) } });
};

// const handleLogin = next => {
//   localStorage.setItem(
//     'USERNAME',
//     btoa(
//       JSON.stringify({
//         loginSource: 2
//       })
//     )
//   );
//   // if (list.length > 1) {
//   //   next({ name: 'select-identity' });
//   // } else if (list.length === 1) {
//   //   const [item] = list;
//   loginFinish(orgId, postId, 2)
//     .then(res => {
//       if (res.flag) {
//         setToken(res.data.token);
//         setUserInfo(res.data);
//         next({ name: 'home' });
//       } else {
//         if (
//           res.errorCode === config.ERR_SIGN_FIRST_RESET ||
//           res.errorCode === config.ERR_SIGN_TIME_OUT
//         ) {
//           let data = {
//             account: res.data.userAccount,
//             pwd: res.data.passWord,
//             tenancyId: res.data.tenancyId,
//             deptId: res.data.deptId,
//             postId: res.data.postId,
//             loginSource: 2
//           };
//           localStorage.setItem('USERNAME', btoa(JSON.stringify(data)));
//           if (res.errorCode === config.ERR_SIGN_FIRST_RESET) {
//             // 首次登录未修改密码
//             next({ name: 'first-reset' });
//           } else {
//             // 距离上次登陆超过72小时
//             next({ name: 'authentication' });
//           }
//         } else {
//           clearToken();
//           handleWechatLoginError(res.errorMsg, next);
//         }
//       }
//     })
//     .catch(() => {
//       handleWechatLoginError('网络异常', next);
//     });
//   // }
// };

const handleLoginCheck = (code, next) => {
  return login(null, null, null, 2, code)
    .then(res => {
      Vue.$loading.hide();
      if (res.flag) {
        // orgId = res.data.orgId;
        // postId = res.data.postId;
        localStorage.setItem(
          'USERNAME',
          btoa(
            JSON.stringify({
              loginSource: 2
            })
          )
        );
        setToken(res.data.token);
        setPostList(res.data.postRoleResList);
        setUserInfo(res.data);
        if (isWeChatMaterial()) {
          next({ name: 'MaterialLibrary' });
        } else if (isWeChatCustomer()) {
          next({ name: 'AssociatedError' });
        } else if (isWeChatNotification()) {
          next({ name: 'Message', query: store.state.mulParameters});
        } else {
          next({ name: 'home' });
        }
        // handleLogin(next);
      } else {
        if (
          res.errorCode === config.ERR_SIGN_FIRST_RESET ||
          res.errorCode === config.ERR_SIGN_TIME_OUT
        ) {
          let data = {
            account: res.data.userAccount,
            pwd: res.data.passWord,
            tenancyId: res.data.tenancyId,
            deptId: res.data.deptId,
            postId: res.data.postId,
            loginSource: 2
          };
          localStorage.setItem('USERNAME', btoa(JSON.stringify(data)));
          if (res.errorCode === config.ERR_SIGN_FIRST_RESET) {
            // 首次登录未修改密码
            next({ name: 'first-reset' });
          } else {
            // 距离上次登陆超过72小时
            next({ name: 'authentication' });
          }
        } else {
          clearToken();
          handleWechatLoginError(res.errorMsg, next);
        }
      }
    })
    .catch(() => {
      handleWechatLoginError('网络异常', next);
    });
};

export async function handleWeChatLogin(next) {
  Vue.$loading.show();
  const { code } = getQueryStringArgs();
  if (!code) {
    await getAccessToken();
    const appId = store.getters.appId;
    let source = '';
    switch (store.state.source) {
    case 'weChat':
      source = '?source=weChat';
      break;
    case 'weChatMaterial':
      source = '?source=weChatMaterial';
      break;
    case 'weChatCustomer':
      source = '?source=weChatCustomer';
      break;
    case 'weChatNotification':
      source = `/message?${getObjToString(store.state.mulParameters)}`;
    }
    const REDIRECT_URI = encodeURIComponent(
      process.env.VUE_APP_CLIENT_URL + source
    );
    const STATE = '';
    const authorizeUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_base&state=${STATE}#wechat_redirect`;
    window.location.href = authorizeUrl;
  } else {
    handleLoginCheck(code, next);
  }
}
