<template>
  <transition
    name="fade"
  >
    <section v-show="show">
      <div class="loading">
        <img
          src="@/assets/img/loading@2x.png"
          alt=""
        >
      </div>
      <div class="loading-text">
        上传中...
      </div>
    </section>
  </transition>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '正在载入...'
    }
  },
  data() {
    return {
      imgs: require('../../assets/img/yoyo.webp')
    };
  },
  methods: {
    variable() {
      return `https://frontend-${process.env.VUE_APP_CURRENTMODE === 'production' ? 'prod' : 'test'}-1256919685.cos.ap-shanghai.myqcloud.com/loading/yoyo.webp`;
      // return process.env.VUE_APP_CURRENTMODE === 'development' ? this.imgs : `${process.env.VUE_APP_CLIENT_URL}/assets/img/yoyo.webp`;
      // return this.imgs;
    }
  }
};
</script>
<style scoped lang="scss">
/* .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}*/
section {
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  height: 120px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  transform: translate(-50%);
}
.loading-text{
  color: $color-FFF;
  font-size: 16px;
  line-height: 20px;
}

.loading{
  /* width: 120px;
  height: 120px;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #02b2c9;
  border-radius: 50%; */
  margin: 25px 0 16px 0;
  img{
    width:34px;
    height:34px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}

@-webkit-keyframes spin {
    0% {
    -webkit-transform: rotate(0deg);
    }
    100% {
    -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
}

</style>
