import Vue from 'vue';
import { selectAllOrgByParam, ssoOrgTreeList, dictList, getOrgAll, selectOrgByOrgType} from '_api/global';
import { changeNewTreeKey, deleteTree, regular } from '@/lib/until';

const actions = {
  getPeopleTree({ commit }) {
    let params = {
      id: '1',
      orgType: 0,
      needDefaultOrg: true,
      needPost: false,
      needStaff: true,
      needCancelStaff: false,
      needChildOrg: true,
      needCancelOrg: false
    };
    selectAllOrgByParam(params).then(res => {
      if (res.flag) {
        let options = changeNewTreeKey([res.data]);
        commit('ORG_TREE', options);
      } else {
        Vue.prototype.$showToast(res.errorMsg);
      }
    });
  },
  departTree({ commit }, data) {
    let bpoFlag = 0;
    ssoOrgTreeList(bpoFlag, data).then(res => {
      if (res && res.flag) {
        let options = deleteTree(res.data.orgs);
        commit('DEPART_TREE', options);
      } else {
        Vue.prototype.$showToast(res.errorMsg);
      }
    });
  },
  getdictList({ commit }) {
    return dictList().then(res => {
      if (res && res.flag) {
        let dicts = {};
        for (let i in res.data.dicts) {
          dicts[i] = [];
          res.data.dicts[i].forEach(item => {
            dicts[i].push({text: item.val, value: item.key });
          });
        }
        commit('DICTLIST', dicts);
      } else {
        Vue.prototype.$showToast(res.errorMsg);
      }
    });
  },
  getOrgAlls({ commit }, data) {
    getOrgAll({orgId: data}).then(res => {
      if (res.flag) {
        let companyType = '';
        if (res.data.companyType === '001-科技体系') {
          companyType = 1;
        } else if (res.data.companyType === '002-人才体系') {
          companyType = 2;
        }
        commit('SYSTEM', companyType);
      }
    });
  },
  getRegionFiliales({ commit }, orgType) {
    selectOrgByOrgType(orgType).then(res => {
      if (res.flag) {
        let branchOrgIdList = [];
        const list = res.data;
        list.forEach(item => {
          branchOrgIdList.push(
            {
              text: regular(item.key),
              value: item.id,
              dep: false
            }
          );
        });
        commit('GetBRANCHLIST', branchOrgIdList);
      } else {
        this.$showToast(res.errorMsg);
      }
    });
  }
};

export default actions;
