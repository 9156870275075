const follow = {
  namespaced: true,
  state: {
    filter: {},
    accompanyList: [],
    aidList: []
  },
  mutations: {
    AID_LIST(state, payload) {
      console.log(payload);
      state.aidList = [...payload];
    },
    FOLLOW_FILTER(state, payload) {
      state.filter = { ...state.filter, ...payload };
    },
    ACCOMPANY_LIST(state, payload) {
      console.log(state);
      console.log(payload);
      state.accompanyList.push(payload);
    },
    DELETE_TAG(state, index) {
      state.accompanyList.splice(index, 1);
    },
    DELETE_ACCOMPANY_LIST(state, payload) {
      state.accompanyList = payload;
    }
  },
  getters: {
    aidList: (state) => state.aidList,
    filterObj: (state) => state.filter,
    followAccompanys: (state) => state.accompanyList
  }

};

export default follow;
